#contact-container {
  display: flex;
  height: 500px;
  position: relative;
  align-items: center;
  background: url('/src/Imagens/png/contact_background.png');
  background-repeat: no-repeat;
  background-size: cover;
}

#mask-contact {
  background: rgba(0, 0, 0, 0.70);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#contact-content {
  display: inline-flex;
  padding-right: 0px;
  align-items: center;
  justify-content: space-around;
  gap: 48px;
  width: 80%;
}

#contact-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 32px;
  position: relative;
}

#contact-right {
  height: 435px;
}


#span-contact {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

#contact-title {
  width: 436px;
  color: #FFF;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#contact-subtitle {
  width: 330px;
  color: var(--white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

#contact-button {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid var(--white, #FFF);
  text-decoration: none;
  color: #FFF;

}

@media (max-width: 768px) {
  #contact-right {
    display: none;
  }

  #contact-container {
    justify-content: center
  }
  #contact-title {
    width: auto;
  }
}

@media (max-width: 609px) {

  #contact-content {
    align-items: center
  }

}