#footer-container {
  height: 100px;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  background-color: #000;
}

#footer-image {
  height: 55px;
  width: 158px;
}

#footer-text {
  color: #FFF;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

@media (max-width: 768px) {
  #footer-text {
    font-size: 10px;
  }

  #footer-container {
    align-items: center;
  }
}