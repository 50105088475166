* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.2s linear;
}

.header-container {
  height: 730px;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.60);
  background: url('/src/Imagens/png/pexels-tom-swinnen.png');
  background-position: center;
  background-size: cover;
  position: relative;
}

header {
  height: 92px;
  display: flex;
  flex-shrink: 0;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;
  position: fixed;
  width: 100%
}

.isScrolled {
  position: fixed;
  z-index: 1000;
  background-color: #FFF;
}


.isScrolled div a span{
  color: #000;
}

.isScrolled #header-logo {
  background: url('/src/Imagens/png/Premie_Horizontal.png');
}


header span {
  color: var(--neutral-colors-light-pure, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  cursor: pointer;
}

.header_container {
  position: relative;
  z-index: 1;
}

#header-logo {
  width: 184px;
  height: 64px;
  flex-shrink: 0;
  background: url('/src/Imagens/png/Premie_Horizontal_Fundo_Roxo.png');
}

#span-header {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 56px;
}

#content-header {
  color: #FFF;
  font-size: 64px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
  position: relative;
  left: 130px;
  top: 326px;
  width: 430px;
  height: 249px;
  display: block;
  cursor: default;
}

#mask {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 730px;
  z-index: 0;
}

#ballon-header {
  position: absolute;
  display: flex;
  width: 59.195px;
  height: 56.092px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  bottom: 70px;
  right: 101px;
  cursor: pointer;
}

#ballon-header:hover {
  transform: scale(1.1);
}

#ballon-header {
  position: fixed;
  right: 5%;
  z-index: 100;
}

.bx-chat {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
}

@media (max-width: 768px) {
  header {
    justify-content: center
  }
  #span-header {
    display: none;
  }
  #ballon-header {
    display: none;
  }
  #content-header {
    left: 59px;
    top: 172px;
    font-size: 50px;
  }
}