.benefits-container {
  display: flex;
  padding: 48px 0px;
  flex-direction: column;
  align-items: center;
  gap: 48px;
  background: #000;

}

#benefits-title {
  color: var(--white, #FFF);
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.cards-benefits {
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

@media (max-width: 768px) {
  .cards-benefits {

    flex-wrap: wrap;
    justify-content: center;

  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .cards-benefits {

    flex-wrap: wrap;
    justify-content: center;
  }

  #card-benefits {
    flex-basis: 30%;
  }
}

@media (max-width: 1080px) {
  .cards-benefits {
    flex-wrap: wrap;
    justify-content: center;

  }

  #card-benefits {
    flex-basis: 30%;
  }
}