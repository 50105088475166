body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --animate-delay: 0.3s;
}

.animate__delay-6s {
  -webkit-animation-delay: 6s;
  animation-delay: 6s;
  -webkit-animation-delay: calc(var(--animate-delay)* 6);
  animation-delay: calc(var(--animate-delay)* 6);
}