#companies-container {
  display: flex;
  height: 325px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  background: #000;

}

#companies-title {
  display: flex;
  padding: 20px 80px 10px 80px;
  align-items: flex-start;
  gap: 10px;
  margin-left: 180px;
}

#companies-title h1 {
  width: 442px;
  color: #FFF;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#companies-cards {
  width: 100%;
  display: flex;
  padding: 24px 0px;
  align-items: center;
  gap: 48px;
  justify-content: center;
}

@media (max-width: 768px) {
  #companies-container {
    height: auto
  }

  #companies-cards {
    flex-wrap: wrap;
  }

  #companies-title {
    margin-left: auto;
    
  }
  #companies-title h1 {
    font-size: 30px;
  }
  
}

@media (min-width: 769px) and (max-width: 1024px) {

  #companies-container {
    height: auto;
  }
  #companies-title {
    margin-left: 0px;
  }

  #companies-cards {
    flex-wrap: wrap;
  }

}

@media (max-width: 1080px) {
  #companies-container {
    height: auto;
  }
  #companies-title {
    margin-left: 0px;
  }

  #companies-cards {
    flex-wrap: wrap;
  }
  #card-companies {
    flex-basis: 30%;
  }
}