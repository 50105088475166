#card-benefits {
  display: flex;
  width: 184px;
  padding: 24px 71px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--purple, #721BE6);
  background: var(--gray-2, #0D0C0F);
  height: 155px;

}

#card-benefits-text {
  color: var(--white, #FFF);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 135.895px;
}

#card-image-benefits {
  width: 42px;
  height: 42px;
}