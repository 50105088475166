#card-companies {
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  width: 184px;
  height: 154px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--purple, #721BE6);
  background: var(--gray-2, #0D0C0F);
}

#card-companies span {
  font-size: 20px;
  color: #fff;
}

@media (max-width: 1366px) {
  #card-companies {
    width: 150px;
    height: 138px;
  }

}