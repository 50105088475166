.about-container {
  display: flex;
  height: 668px;
  padding: 26px 95px 26px 72px;
  justify-content: space-around;
  align-items: center;
  flex-shrink: 0;
  background: #000;
  gap: 20px
}

.about-image {
  width: 848px;
  height: 590px;
  background: url('../Imagens/png/Mask_group.png');
  background-position: center;
  background-size: cover;
  position: relative;
}

#about-text {
  display: flex;
  flex-direction: column;
  width: 300px;
}

#about-text-before-animation h1 {
  display: flex;
  flex-direction: column;
  width: 300px;
  color: #000;
}

#about-text-before-animation span {
  display: flex;
  flex-direction: column;
  width: 300px;
  color: #000;
}

#about-text h1 {
  width: 293px;
  color: var(--white, #FFF);
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 24px;
}

#about-text span {
  display: block;
  width: 289px;
  color: var(--white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

@media (max-width: 768px) {
  .about-image {
    display: none;
  }
}