.solutions-container {
  display: flex;
  height: 498px;
  padding: 80px 147px;
  justify-content: space-around;
  align-items: center;
  gap: 80px;
  flex-shrink: 0;
  background: var(--black, #000);
}

#solutions-carousel {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 19px;
}

#solutions-carousel-title {
  display: flex;
  width: 431px;
  height: 43px;
  flex-direction: column;
  justify-content: center;
  color: var(--white, #FFF);
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#solutions-carousel-subtitle {
  color: var(--purple, #721BE6);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#solutions-carousel-content {
  width: 368px;
  color: var(--white, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

#solutions-scrollbar-container {
  display: flex;
  width: 455px;
  align-items: center;
  gap: 24px;
}

#solutions-carousel-scrollbar {
  width: 330px;
  height: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  opacity: 0.85;
  background: var(--neutral-colors-dark-pure, #0A0C10);
}

#solutions-scrollbar-picker {
  width: 110px;
  height: 4px;
  flex-shrink: 0;
  border-radius: 8px;
  background: var(--purple, #721BE6);
}

#arrows-container {
  display: flex;
  width: 104px;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
}

#solutions-image {
  height: 433px;
  width: 651px;
  background: url('../Imagens/png/carrossel1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

}

#left-arrow,
#right-arrow :hover {
  cursor: pointer;
}

@media (max-width: 768px) {
  #solutions-image {
    display: none
  }

  #solutions-carousel {
    align-items: center
  }

  #solutions-carousel-title {
    text-align: center;
    font-size: 40px;
  }

  #solutions-carousel-scrollbar {
    display: none;

  }

  #arrows-container {
    width: 100%
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  #solutions-image {
    display: none
  }
}

@media (max-width: 1080px) {
  #solutions-image {
    display: none;
  }
}